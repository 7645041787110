import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VideoPlayer from '../VideoPlayerSimple'; // Adjust path if needed
import SearchSpreadSheet from './SearchSpreadSheet'; // Adjust path if needed
import './CourseWorkApp.css'; // Your custom styles

const CourseWorkComponent = ({ data }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panelIndex) => (event, isExpanded) => {
    setExpanded(isExpanded ? panelIndex : false);
  };

  const summaryStyles = [
    { backgroundColor: '#FFE5D4' }, // Light Peach
    { backgroundColor: '#EDF7FF' }, // Very Light Blue
    { backgroundColor: '#C8E6C9' }, // Light Green
    { backgroundColor: '#E1BEE7' }, // Light Purple
  ];

  if (!data || !Array.isArray(data)) {
    console.error('Invalid data passed to CourseWorkComponent:', data);
    return <div>Error: Invalid data format</div>;
  }

  return (
    <Box>
      {/* Collapsible Menu */}
      {data.map((section, index) => (
        <Accordion
          key={index}
          expanded={expanded === index}
          onChange={handleChange(index)}
          sx={{
            borderRadius: '8px',
            marginBottom: '16px',
            '&:before': {
              display: 'none',
            },
            boxShadow: 'none', // Remove default shadow
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: '#007BFF' }} />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            sx={{
              backgroundColor: summaryStyles[index % summaryStyles.length].backgroundColor,
              color: '#333333',
              borderRadius: '8px',
              minHeight: '64px',
              display: 'flex',
              justifyContent: 'center', // Center title horizontally
              alignItems: 'center', // Center title vertically
            }}
          >
            <Typography variant="h6" style={{ textAlign: 'center', width: '100%' }}>
              {section.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              padding: '24px',
              backgroundColor: '#FFF1E0', // Light cream color for content
            }}
          >
            {/* Content Section */}
            <Box>
              {/* Media Section (Video or Image) */}
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                <div style={{ flex: '1 1 60%', minWidth: '300px' }}>
                  {section.video ? (
                    <VideoPlayer url={section.video} width="100%" height="400px" />
                  ) : section.image ? (
                    <img
                      src={section.image}
                      alt={section.title || 'Course image'}
                      style={{
                        width: '100%',
                        height: 'auto',
                        display: 'block',
                        borderRadius: '8px',
                      }}
                    />
                  ) : (
                    <Typography variant="body1" color="textSecondary">
                      No media available
                    </Typography>
                  )}
                </div>

                {/* Course Details */}
                <div style={{ flex: '1 1 35%', minWidth: '250px', marginLeft: '20px' }}>
                  <Typography variant="h6" style={{ color: '#333333' }}>
                    {section.title}
                  </Typography>
                  <Typography variant="body1" style={{ marginBottom: '20px', color: '#555555' }}>
                    {section.caption}
                  </Typography>
                  <Typography variant="body2" style={{ color: '#555555' }}>
                    {section.description}
                  </Typography>

                  {/* Tags */}
                  <Box mt={2}>
                    {section.tags &&
                      section.tags.map((tag, idx) => (
                        <Paper key={idx} elevation={3} style={{ padding: '10px', marginTop: '10px', backgroundColor: '#F5F5F5' }}>
                          <Typography variant="body1" style={{ color: '#333333' }}>
                            {tag}
                          </Typography>
                        </Paper>
                      ))}
                  </Box>
                </div>
              </div>

              {/* Spreadsheet Viewer */}
              {section.sheetUrl && (
                <Box mt={3}>
                  <SearchSpreadSheet sheetUrl={section.sheetUrl} />
                </Box>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default CourseWorkComponent;