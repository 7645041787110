import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Grid, Typography, Link, Chip } from "@material-ui/core";
import styled from "@emotion/styled";

const fontColor = "#333"; // common font color for both the title and content

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    // Reduce padding on smaller screens
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
  titleGridItem: {
    color: 'black', // same font color for title
  },
  contentGridItem: {
    color: fontColor, // same font color for content
  },
  title: {
    fontWeight: 400,
    fontSize: '1.5rem',
    color: 'black', // ensuring title color matches content
  },
  content: {
    fontSize: '1rem',
    color: fontColor, // ensuring content color matches title
    backgroundColor: '#f5f5f5', // light gray background for content
    borderRadius: '4px',
    padding: '8px',
    whiteSpace: 'pre-wrap', // ensures array items appear on new lines
  },
  link: {
    display: 'block',
    marginTop: theme.spacing(1),
  },
  tags: {
    marginTop: theme.spacing(2), // Increased space above the tags
    '& > *': {
      margin: theme.spacing(0.5), // Margin around each tag
    },
  },
}));

const RowContainer = styled(Grid)`
  border-bottom: 2px solid #e0e0e0; // Adds a bottom border to each row
  padding: 1rem;
  color: ${fontColor};
  margin-bottom: 1rem; // Adds spacing between rows
`;

const StyledCard = styled(Card)`
  height: 100%;
  background-color: #fff; // White background for the cards
  box-shadow: 0px 0px 8px rgba(0,0,0,0.1); // Soft shadow for depth
  border-radius: 8px; // Rounded corners for the cards
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; // Add transition for smooth effect

  &:hover {
    transform: translateY(-5px) scale(1.02); // Lifts the card slightly and scales up
    box-shadow: 0px 15px 25px rgba(0,0,0,0.2); // Deepens the shadow on hover
  }

  & .MuiCardContent-root { // Targets the CardContent directly
    padding: 16px; // Adjusts the padding of CardContent
    &:last-child {
      paddingBottom: 16px; // Adjusts the last-child paddingBottom to match
    }
  }
`;

const TwoPartRow = ({ cardsContent }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.gridContainer}>
      {cardsContent.map((card, index) => (
        <RowContainer container spacing={3} key={index} xs={12}>
          <Grid item xs={12} sm={4} className={classes.titleGridItem}>
            <StyledCard>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                  {card.title}
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={8} className={classes.contentGridItem}>
            <StyledCard>
              <CardContent>
                <Typography variant="body2" component="p" className={classes.content}>
                  {Array.isArray(card.content) ? card.content.join('\n') : card.content}
                </Typography>
                {card.link && (
                  <Link href={card.link} className={classes.link} target="_blank" rel="noopener">
                    Learn More
                  </Link>
                )}
                {card.tags && card.tags.length > 0 && (
                  <div className={classes.tags}>
                    {card.tags.map((tag, tagIndex) => (
                      <Chip key={tagIndex} label={tag} />
                    ))}
                  </div>
                )}
              </CardContent>
            </StyledCard>
          </Grid>
        </RowContainer>
      ))}
    </Grid>
  );
};

export default TwoPartRow;